import { createBrowserRouter, Navigate } from "react-router-dom";
import IndexDataForMp from "./pages/indexDataForMp";
import QrCode from "./pages/qrcode";
import ChannelSource from "./pages/channelSource";
const router = [
  {
    path: "/indexDataForMp",
    Component: IndexDataForMp,
  },
  {
    path: "/zzt-share/qrcode.html",
    Component: QrCode,
  },
  {
    path: "/qrcode.html",
    Component: QrCode,
  },
  {
    path: "/zzt-share/channelSource.html",
    Component: ChannelSource,
  },
];
export default createBrowserRouter(router);
