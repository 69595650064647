/*
 * @Description: 
 * @Author: LZ
 * @Date: 2024-08-15 17:21:18
 * @LastEditTime: 2024-10-11 15:34:47
 */
import axios from "axios";
import { message, notification } from "antd";
import dayjs from "dayjs";
import { getUserInfo, getQueryString } from "../utils/yuxunUtils";

/**
 * 配置request请求时的默认参数
 */

const request = axios.create({
  // baseURL:'http://debugwork.yxgzsj.com',
  // timeout:TIMEOUT
});

request.defaults.headers.post["Content-Type"] = "application/json";

// request拦截器, 改变url 或 options.
request.interceptors.request.use((config) => {
  console.log(config);
  config.headers = {
    TIMESTAMP: dayjs().valueOf(),
    "Cache-Control": "no-cache",
    ...config.headers,
  };
  if (getUserInfo("token")) {
    config.headers.Authorization = `Bearer ${getUserInfo("token")}`;
  }
    // 从URL中获取cluster参数
    const cluster = getQueryString('cluster');

    // 如果cluster存在，则添加到请求头
    if (cluster) {
      config.headers['cluster'] = cluster; // 假设服务器期望的header键名为X-Cluster-ID
    }

  return config;
});

// // response拦截器, 处理response
request.interceptors.response.use(
  (res) => {
    console.log(111111, res)
    if (res?.status != 200) {
      const errorText = res?.data?.error || res?.data?.message || res?.data?.statusText;
      message.error(errorText)
    }

    return res.data;
  },
  (error) => {
    console.log(error)
    if (error?.config?.hasOwnProperty('showError') && error?.config?.showError === false) {
    }else{
      const errorText = error?.error || error?.message || error?.statusText;
      message.error(errorText)
    }

    return error;
  }
);

export default request;
