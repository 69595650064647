import { useEffect, useState } from "react"
import * as API from '../../services/services';
import { getQueryString } from "../../utils/yuxunUtils";
import { Spin, Image } from "antd";
import './index.less'

const QrCode = () => {
  const [remark, setRemark] = useState('')
  const [iswx, setIswx] = useState(true)
  const [loading, setLoading] = useState(true)
  const [url, setUrl] = useState('')
  const [error, setError] = useState('请使用微信进行扫码')

  useEffect(() => {
    document.title = "客服活码";
    getQrcode()
  }, [])

  const getQrcode = async () => {
    setLoading(true)
    const res = await API.getLivecode({
      params: {
        liveCodeId: getQueryString('liveCodeId')
      },
      showError: false
    })
    console.log(res)
    if (res?.url) {
      setRemark(res?.remark)
      setUrl(res?.url)
    } else {
      setIswx(false)
      setError(res?.response?.data.errorDescription || '请使用微信进行扫码')
    }
    setLoading(false)

  }

  return <div className="wrapper" id="root">
    <Spin spinning={loading}>
      <div className="qrcode" >
        {
          iswx ? <div>
            {
              url && !loading && <Image preview={false} src={url} alt="二维码" ></Image>
            }
            {
              !url && !loading && <div>二维码已失效</div>
            }
            {
              remark && <div className="tip" >{remark}</div>
            }
            {
              url && <div className="btn">长按二维码，添加好友</div>
            }
          </div> : error
        }
      </div>
    </Spin>
  </div>
}
export default QrCode