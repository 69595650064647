import React, { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { ConfigProvider } from "antd";
import zhCN from "antd/locale/zh_CN";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <RouterProvider router={router}/>
    </ConfigProvider>
  );
}

export default App;
