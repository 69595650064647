import { Card, Typography, Col, Row, Table, Select, Spin } from 'antd';
import './index.less'
import { useEffect, useState } from 'react';
import * as API from '../../services/services';
import _ from 'lodash'
import { getQueryString } from '../../utils/yuxunUtils';
import dayjs from 'dayjs'

const tabPane = [
  {
    value: 'conversationTop',
    label: '30s电话量TOP10',
    isShow: true,
    index: '1',
  },
  {
    value: 'clientIncreaseTop',
    label: '客户增长Top10',
    isShow: true,
    index: '2',
  },
  // {
  //   label: '企微沟通TOP10',
  //   value: 'weworkData.communicateTop',
  //   type: 'qw',
  //   isShow: true,
  //   index: '3',
  // },
  // {
  //   label: '企微好友增长TOP10',
  //   value: 'weworkData.raiseTop',
  //   type: 'qw',
  //   isShow: true,
  //   index: '4',
  // },
  {
    label: '个微沟通TOP10',
    value: 'wxData.communicateTop',
    type: 'gw',
    isShow: true,
    index: '5',
  },
  {
    label: '个微好友增长TOP10',
    value: 'wxData.raiseTop',
    type: 'gw',
    isShow: true,
    index: '6',
  }
]

const columns = [
  {
    title: '排名',
    render: (text, record, index) => `${index + 1}`,
  },
  {
    title: '员工姓名',
    dataIndex: 'userName',
    key: 'userName'
  },
  {
    title: '所属部门',
    dataIndex: 'deptName',
    key: 'deptName'
  },
  {
    title: '数量',
    dataIndex: 'count',
    key: 'count'
  },
]
const IndexDataForMp = () => {
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('conversationTop')
  const [chartData, setChartData] = useState({})
  const [topData, setTopData] = useState({})
  const [reportData, setReportData] = useState({})

  useEffect(() => {
    getInit()
    document.title = "数据报表";
  }, [])

  const getInit = async () => {
    if (!getQueryString('eid')) return
    setLoading(true)
    const res = await API.getIndexShareForMp({
      data: {
        eid: getQueryString('eid'),
        managerId: getQueryString('managerId'),
        startTime: getQueryString('startTime'),
        endTime: getQueryString('endTime'),
      },
      headers: {
        SOURCE: 9,
        'ENTERPRISE-ID': getQueryString('eid'),
      }
    })
    setChartData(res?.data?.chartData || {})
    setTopData(res?.data?.topData || {})
    setReportData(res?.data?.reportData || {})
    setLoading(false)
  }
  const renderColumns = () => {
    return (reportData?.indexFreeReportTitles || [])?.map(item => ({
      dataIndex: item.key,
      title: item.displayName,
    }))
  }
  return <div className="pageContent">
    <Typography.Title level={5}>{getQueryString('startTime') && dayjs(getQueryString('startTime') / 1).format('MM月DD日')}数据报表</Typography.Title>
    <Card>
      <Spin spinning={loading}>
        <div className='items'>
          <div>好友总数</div>
          <div>{chartData.wxData?.wxFriendCount || 0}</div>
        </div>
        <Row>
          <Col xs={12} sm={6} className='cols'>
            <div className='items'>
              <div>平均新增粉丝数</div>
              <div>{chartData.wxData?.wxFriendIncreaseCount || 0}</div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className='items'>
              <div>平均个微聊天数</div>
              <div>{chartData.wxData?.wxFriendTalkCount || 0}次</div>
            </div>
          </Col>
          <Col xs={12} sm={6} className='cols'>
            <div className='items'>
              <div>个微平均响应时间</div>
              <div>{Math.floor(chartData.wxData?.wxResponseAveTime / 60) || 0}分{chartData.wxData?.wxResponseAveTime % 60 || 0}秒</div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className='items'>
              <div>平均电话呼叫量</div>
              <div>{chartData.wxData?.callOutCount || 0}</div>
            </div>
          </Col>
        </Row>
      </Spin>
    </Card>
    <Card className='marginTop18'>
      <div className='title'>Top10</div>
      <Select options={tabPane} style={{ width: 200, margin: '12px 0px' }} value={type} onChange={v => setType(v)} />
      <Table bordered dataSource={_.get(topData, type, [])} columns={columns} pagination={false} loading={loading} scroll={{ x: 'max-content' }} />
    </Card>

    <Card className='marginTop18'>
      <Table bordered dataSource={_.get(reportData, 'indexFreeReportData')} columns={renderColumns()} loading={loading} scroll={{ x: 'max-content' }} />
    </Card>
  </div>
}
export default IndexDataForMp