import { useEffect, useState } from 'react'
import './index.less'
import { Spin, DatePicker, Button,Empty  } from 'antd'
import * as API from '../../services/services';
import dayjs from 'dayjs';
import { getQueryString } from '../../utils/yuxunUtils';

const ChannelSource = () => {
  const [loading, setLoading] = useState(false)
  const [souceData, setSouceData] = useState({})
  const [time, setTime] = useState(null)

  useEffect(()=>{
    document.title = "渠道来源";
  },[])

  const getSearch = async () => {
    const res = await API.getStatisticsExternal({
      data: {
        configId: getQueryString('configId'),
        startTime: dayjs(time[0])?.valueOf(),
        endTime: dayjs(time[1])?.valueOf(),
      }
    })
    setSouceData(res?.data?.[0] || {})
    setLoading(false)
  }

  return <div className="channelSourceWrapper">
    <Spin style={{ padding: '15px' }} spinning={loading}>
      <div className="time">
        <DatePicker.RangePicker showTime value={time} onChange={date => setTime(date)} />
        <div className='btnWrap'>
          <Button disabled={!time} type="primary" onClick={getSearch}>查询</Button>
        </div>
      </div>

      {
        JSON.stringify(souceData) !== '{}' ? <>
          <div className="source-title">
            <h3>渠道来源</h3>
          </div>
          <div className="source">
            <div className="source-item">
              <div>活码名称</div>
              <div>{souceData.remark || ''}</div>
            </div>
            <div className="source-item">
              <div>累计好友人数</div>
              <div>{souceData.totalNum || 0}</div>
            </div>
            <div className="source-item">
              <div>留电客户数</div>
              <div>{souceData.contactNum || 0}</div>
            </div>
            <div className="source-item">
              <div>目前好友人数</div>
              <div>{souceData.friendNum || 0}</div>
            </div>
            <div className="source-item">
              <div>删除好友人数</div>
              <div>{souceData.delNum || 0}</div>
            </div>
            {
              souceData.livecodeTags?.map((item, i) => <div className="source-item" key={i}>
                <div>{item.title}</div>
                <div>{item.num}</div>
              </div>)
            }
          </div>
        </>:<Empty />
      }
    </Spin>
  </div>
}
export default ChannelSource