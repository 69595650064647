import request from "./request";

// 获取推广页数据
export async function getIndexShareForMp(payload) {
  return request('/api/indexpage/share/forMp', {
    method: 'POST',
    ...payload,
  });
}

//获取二维码
export async function getLivecode(payload) {
  return request(`/api/v0/livecode/scan`, {
    method: 'POST',
    ...payload,
  });
}

//获取渠道
export async function getStatisticsExternal(payload) {
  return request(`/api/wework/livecode/statistics/external/${payload.data.configId}`, {
    method: 'POST',
    ...payload,
  });
}